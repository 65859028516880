<template>
  <div class="flex-between-start mt5" style="color: #333333">
    <!-- 中间内容部分 -->
    <div class="centerContent">
      <div class="topImg">
        <!-- 首页(单图) -->
        <div class="swiper">
          <img :src="home.head_banner" alt />
        </div>
        <!-- 轮播下面的文字展示 -->
        <div class="bannerMsg">
          <p class="bannerMsgText">{{ $t("bannerText") }}</p>
        </div>
      </div>
      <!-- 文讯 -->
      <div class="newsList mt20">
        <news-list :study="study" :activity="activity"></news-list>
      </div>
      <div class="btmImg mt20">
        <img :src="home.foot_banner" alt />
      </div>
    </div>
    <!-- 右侧内容部分 -->
    <div class="right">
      <!-- 法相 -->
      <div class="lawPhase">
        <!-- 点击要跳转到耕云导师法相页面-->
        <h3 @click="togydsfx" class="title">{{ $t("zenFrech") }}</h3>
        <el-carousel
          ref="car"
          indicator-position="none"
          :interval="6000"
          height="276px"
          arrow="never"
        >
          <el-carousel-item v-for="(item, index) in picList" :key="index">
            <img
              @click="linkToLaw"
              style="width: 100%; height: 100%;"
              :src="item.picture_url"
              alt
            />
          </el-carousel-item>
        </el-carousel>
        <!-- 按钮 -->
        <div class="swiperBtn flex-between">
          <img src="../static/img/home/left.png" alt @click="handlePre" />
          <img src="../static/img/home/right.png" alt @click="handleNext" />
        </div>
      </div>
      <div class="line"></div>
      <ul class="lis" style="min-height: 112px">
        <li
          class="oneLine"
          @click="linkToDetail(item)"
          v-for="(item, index) in rightNews"
          :key="index"
        >
          <div class="square"></div>
          {{ item.news_title }}
        </li>
        <!-- <li>
                    <div class="square"></div>安祥禪申明啟示
                </li>
                <li>
                    <div class="square"></div>安祥禪申明啟示
                </li>
                <li>
                    <div class="square"></div>安祥禪申明啟示
                </li> -->
      </ul>
      <div class="line"></div>
      <!-- 禅曲 -->
      <div class="music">
        <img src="../static/img/home/music.png" alt />
        <!-- <audio :src="musicList[0].audio_url" controls class="audio"></audio> -->
        <audio ref="myaudio" src="http://www.anhsiangchan.org.tw/uploads/video/29.mp3" controls class="audio"></audio>
        <p class="startPlay" @click="playAudio">{{ $t("startPlay") }}</p>
        <div @click="linkToMusic" class="musicMore flex-end">
          <span>{{ $t("moreMusic") }}</span>
          <div class="moreBtn flex-center">
            <img src="../static/img/home/right2.png" alt />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="webManage">
        <p>{{ $t("footer").title }}</p>
        <img src="../static/img/home/right_bottom.png" alt />
      </div>
    </div>
  </div>
</template>

<script>
import NewsList from "../components/News.vue";
import { getCateList, getPicture, getHome, getAudio,addviewnum } from "../api/apis";
export default {
  name: "home",
  components: {
    NewsList,
  },
  data() {
    return {
      home: {
        head_banner: require("../static/img/home/banner1.png"),
      },
      study: [], //最新闻讯信息
      activity: [], //	最新活动信息
      picList: [], //法相的图片列表
      musicList: [], //禅曲列表
      rightNews: [],
      // before_name:'',
      current_cate: "",
      date: {
        year: "",
        month: "",
        day: "",
        week: "",
      },
    };
  },
  methods: {
    //点击跳转耕耘导师法相页面
    togydsfx() {
      // window.open(
      //   "https://chanxue.pc.langqiyun.cn/plowCloudMentor/law?before_name=耕耘導師&current_cate=耕耘導師法相",'_self'
      // );
      this.$router.push({
        path:'/plowCloudMentor/law',
        query:{
          before_name:'耕耘導師',
          current_cate:'耕耘導師法相'
        }
      })
    },

    getHomeInfo() {
      getHome().then((res) => {
        this.home = res.home;
        this.study = res.study;
        this.activity = res.activity;
        this.rightNews = res.new || [];
      });
    },
    handlePre() {
      this.$refs.car.prev();
    },
    handleNext() {
      this.$refs.car.next();
    },
    //跳转到法相页面
    linkToLaw() {
      this.$router.push({
        path: "/plowCloudMentor/law",
      });
    },
    linkToDetail(item) {
      window.open(
        this.$BaseURL + "/articleDetail?name=information&id=" + item.id
      );
      // this.$router.push({
      //     path: "/articleDetail",
      //     query: {
      //         name: "information",
      //         id: item.id
      //     }
      // }); //跳转到心得详情
    },
    //跳转到安详禅曲
    linkToMusic() {
      localStorage.setItem("nowIndex1", 1);
      localStorage.setItem("nowIndex2", 6);
      this.$router.push({
        path: "/magicWeapon/zenMusic",
        query: {
          before_name: this.$t("navLink")[1].name,
          current_cate: this.current_cate,
        },
      });
    },
    //播放音乐
    playAudio() {
      console.log("播放", this.$refs.myaudio);
      this.$refs.myaudio.play();
    },
  },
  mounted() {
    
    getAudio().then((res) => {
      this.musicList = res;
      this.$refs.myaudio.src = res[0].audio_url;
    });
    //获取home页面的数据
    this.getHomeInfo();
    getCateList().then((res) => {
      let id = res[0][1].id;
      this.current_cate = res[1][6].cate_name;
      getPicture(id, {
        page: 1,
        limit: 6,
      }).then((res) => {
        this.picList = res.data;
      });
    });
  },
  created(){
    // addviewnum().then((res) => {
    //   console.log(res);
    // });
  },
};
</script>

<style lang="stylus" scoped>
.line {
  width: 100%;
  height: 1px;
  background-color: #E2E2E2;
  margin: 10px 0px;
}

.swiper1 {
  width: 190px;
  height: 206px;
}

.centerContent {
  width: 594px;

  .topImg {
    .swiper {
      width: 100%;
      color: #000;
      font-size: 15px;
    }

    .swiper img {
      width: 100%;
    }

    .bannerMsg {
      font-size: 15px;
      margin-top: -5px;
      /* 纠正尺寸 */
      background-image: url('../static/img/home/bannerbg.png');
      background-size: 100%;
      background-repeat: no-repeat;
      height: 37px;
      line-height: 37px;
      color: #f83b0bff;
      overflow: hidden;
    }

    .bannerMsgText {
      animation: 20s wordsLoop linear infinite normal;
    }

    @keyframes wordsLoop {
      0% {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
      }
    }

    @keyframes wordsLoop {
      0% {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
      }
    }
  }

  .btmImg {
    width: 594px;

    img {
      width: 100%;
    }
  }
}

.right {
  width: 190px;
  border: 1px solid #E2E2E2;
  /* font-size: 14px; */
  font-size: 15px;

  .lawPhase {
    text-align: center;
    cursor :pointer;
	position: relative;
    .title {
      color: #3890D7;
      /* font-size: 14px; */
      font-size: 15px;
      line-height: 28px;
    }

    .swiperBtn {
      width: 100%;
      bottom: 10px;
      z-index: 9999999999999;
      position: absolute;

      img {
        cursor: pointer;
		
      }
    }
  }

  .lis {
    ul {
      text-align: left;
    }

    li {
      /* font-size: 14px; */
      font-size: 15px;
      line-height: 28px;
      text-align: left;
      padding-left: 10px;

      div {
        display: inline-flex;
      }

      .square {
        width: 7px;
        height: 7px;
        margin-right: 10px;
        background-color: #237FC8;
      }
    }

    li:hover {
      cursor: pointer;
      color: #2b80c3;
    }
  }

  .music {
    .audio {
      width: 82%;
      height: 20px;
      margin-top: 10px;
    }

    .startPlay {
      text-align: left;
      margin-left: 20px;
      color: #237FC8;
      line-height: 28px;
      cursor: pointer;
    }

    .musicMore {
      margin: 10px;
      text-align: right;
      cursor: pointer;

      span, div {
        display: inline-block;
      }

      .moreBtn {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #000000;

        img {
          width: 12px;
        }
      }
    }
  }

  .webManage {
    p {
      text-align: left;
      color: #237FC8;
      margin: 10px;
      margin-left: 19px;
    }

    img {
      width: 174px;
      height: 140px;
      margin-bottom: 10px;
    }
  }
}
</style>