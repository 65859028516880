<template>
  <!-- 路由跳转 -->
  <div class="navLink">
    <div class="flex-between" style="width: 100%">
      <div v-for="(item, index) in navLinkList" :key="index" class="linkItem">
        <div v-show="index !== 5" :class="{ activeBg: nowIndex == index }">
          {{ $t("navLink")[index].name }}
        </div>
        <div
          v-show="index === 5"
          :class="{ activeBg: nowIndex == index }"
          @click="linkToHome"
        >
          {{ $t("navLink")[index].name }}
        </div>
        <!-- 子导航 -->
        <ul class="childNav">
          <li
            @click="linkToPage(item1, $t('navLink')[index].name, index, index1)"
            v-for="(item1, index1) in childNav[index]"
            :key="index1"
          >
            {{ item1.cate_name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="line"></div>
    <ul class="twoNav flex-start">
      <li class="home">
        <div class="img flex-center">
          <img src="../static/img/home/home.png" alt />
        </div>
        <div>{{ $t("setToHome") }}</div>
      </li>
      <li
        class="home love"
        href="javascript:void(0);"
        title="wecandoitforev-baidujingyan"
        @click="addFavorite2"
      >
        <div class="img flex-center">
          <img src="../static/img/home/love.png" alt />
        </div>
        <div>{{ $t("joinLove") }}</div>
      </li>
    </ul>
  </div>
</template>



<script>
import { getCateList } from "../api/apis";
export default {
  name: "navLink",
  watch: {
    $route() {
      this.nowIndex = localStorage.getItem("nowIndex1") || 5;
    },
  },
  data() {
    return {
      nowIndex: 5,
      navLinkList: [
        {
          id: 2,
          name: "耕雲導師",
          link: "/plowCloudMentor",
        },
        {
          id: 3,
          name: "安祥禪法寶",
          link: "/magicWeapon",
        },
        {
          id: 4,
          name: "禪友文訊",
          link: "/friendWen",
        },
        {
          id: 5,
          name: "結縁品及下載",
          link: "/greenAndDownload",
        },
        {
          id: 6,
          name: "活動訊息",
          link: "/information",
        },
        {
          id: 1,
          name: "首頁",
          link: "/",
        },
      ],
      childNav: [],
      childLink: [
        [
          "/plowCloudMentor/brief",
          "/plowCloudMentor/law",
          "/plowCloudMentor/taoist",
          "/plowCloudMentor/mourn",
          "/plowCloudMentor/firstDraft",
          "/plowCloudMentor/anxiang",
          "/plowCloudMentor/menory",
        ],
        [
          "/magicWeapon/word",
          "/magicWeapon/puzzleBook",
          "/magicWeapon/opus",
          "/magicWeapon/poetry",
          "/magicWeapon/videos",
          "/magicWeapon/frenchp",
          "/magicWeapon/zenMusic",
          "/magicWeapon/ancient",
        ],
        ["/friendWen/experience", "/friendWen/letter"],
        [
          "/greenAndDownload/show",
          "/greenAndDownload/ideaBook",
          "/greenAndDownload/downloadBox",
        ],
        ["/information"],
      ],
    };
  },
  methods: {
    addFavorite2() {
      var url = window.location ;
      var title = document.title;
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("360se") > -1) {
        alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
      } else if (ua.indexOf("msie 8") > -1) {
        window.external.AddToFavoritesBar(url, title); //IE8
      } else if (document.all) {
        try {
          window.external.addFavorite(url, title);
        } catch (e) {
          alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
        }
      } else if (window.sidebar) {
        window.sidebar.addPanel(title, url, "");
      } else {
        alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
      }
    },
    addLink(arr) {
      //除了处理跳转链接以外，还需要处理多语言（多语言的字段:cate_name:繁体，j_name：简体，e_name是英文）
      let _this = this;
      let language = 2; //1简体；2繁体3英文

      return arr.map((item, index) => {
        return item.map((item2, index2) => {
          if (language == 1) {
            item2.cate_name = item2.j_name;
          } else if (language == 2) {
            item2.cate_name = item2.cate_name;
          } else {
            item2.cate_name = item2.e_name;
          }

          item2.link = _this.childLink[index][index2];
          return item2;
        });
      });
    },
    linkToHome() {
      this.$router.push({
        path: "/",
      });
      this.nowIndex = 5;
      localStorage.setItem("nowIndex1", 5);
    },
    linkToPage(item, parse_name, index, index1) {
      this.nowIndex = index;
      localStorage.setItem("nowIndex1", index);
      localStorage.setItem("nowIndex2", index1);
      this.$router.push({
        path: item.link,
        query: {
          before_name: parse_name,
          current_cate: item.cate_name,
        },
      });
    },
  },
  mounted() {
    this.nowIndex = localStorage.getItem("nowIndex1") || 5;
    getCateList().then((res) => {
      //需要交换一下 下载 和 活动讯息 的位置（也就是下标为3和4的数据位置）
      let changeItem = res[3];
      res[3] = res[4];
      res[4] = changeItem;

      this.childNav = this.addLink(res);
      // this.childNav = res;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.navLink {
  /* display: grid;
  display: -ms-grid;
  grid-template-columns: repeat(6, 16.67%);
  -ms-grid-columns: 16.67% 16.67% 16.67% 16.67% 16.67% 16.67%;
  grid-column-gap: 1px;
  -ms-grid-column-gap: 1px; */
  /* font-family: "幼圆"; */
  font-family: 'YouYuan';
  font-weight: 600;
}

.linkItem {
  background: linear-gradient(
    180deg,
    rgba(110, 110, 110, 1) 0%,
    rgba(37, 37, 37, 1) 100%
  );
  width: 16.67%;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  color: #fff;
  /* font-weight: 600; */
  /* font-weight: normal; */
  font-size: 15px;
  position: relative;

  .activeBg {
    background: linear-gradient(180deg, rgba(72, 72, 72, 1) 0%, rgba(16, 16, 16, 1) 100%);
  }

  .childNav {
    position: absolute;
    width: 160px;
    padding-top: 4px;
    display: none;
    z-index: 2;

    li {
      background-color: #ffffff;
      cursor: pointer;
      height: 30px;
      line-height: 30px;
      color: #3890D7;
      border: 1px solid #000000;
      border-bottom: 0px solid #000000;
      font-size: 14px;
    }

    li:last-child {
      border-bottom: 1px solid #000000;
    }

    li:hover {
      color: #ffffff;
      background-color: #000000;
    }
  }
}

.linkItem:hover {
  .childNav {
    display: block;
  }
}

.linkItem a {
  color: #fff;
  text-decoration: none;
}

.line {
  width: 100vw;
  min-width: 980px;
  height: 4px;
  background-color: #237fc8;
}

.twoNav {
  cursor: pointer;
  width: 170px;
  height: 42px;
  color: #666666;
  font-size: 12px;
  /* margin-left: -153px; */
  margin-top: 10px;
  margin-left: 10px;
  font-weight: normal;

  .img {
    width: 15px;
    height: 14px;
    margin-right: 5px;
  }

  ul {
    li {
      height: 16px;
      width: 50%;
    }
  }

  .home {
    border-right: 2px solid #6C6C6C;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    padding-right: 5px;
  }

  .love {
    border: none;
  }
}
</style>