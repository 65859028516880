<!-- 安详禅法宝 -->
<template>
	<div class="margicWeapon">
		<filePath>
			<div slot="parseName">{{before_name}}</div>
			<div slot="currentName">{{current_cate}}</div>
		</filePath>
		<div>
	
				<router-view></router-view>
	

		</div>
	</div>
</template>

<script>
	import filePath from "../../components/filePath";
	export default {
		name: "plowCloud",
		components: {
			filePath
		},
		watch: {
			$route() {
				this.before_name = this.$route.query.before_name;
				this.current_cate = this.$route.query.current_cate;

			}
		},

		data() {
			return {
				before_name: "",
				current_cate: ""
			};
		},
		methods: {},
		created() {
			this.before_name = this.$route.query.before_name;
			this.current_cate = this.$route.query.current_cate;
		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.margicWeapon {
		width: 100%;
	}
</style>