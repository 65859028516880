import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/home.vue'
import More from './views/more.vue'

import PlowCloudMentor from './views/plowCloudMentor/index.vue'

import FriendWen from './views/friendWen/index.vue'
import GreenAndDownload from './views/greenAndDownload/index.vue'
import Information from './views/information/index.vue'
import MagicWeapon from './views/magicWeapon/index.vue'

//详情
import ArticleDetail from './views/detail/articleDetail.vue'
import MusicDetail from './views/detail/musicDetail.vue'
import ExperienceDetail from './views/detail/experienceDetail.vue'




Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [{
        path: "/",
        component: Home
    },
    {
        path: 'brief',
        component: () =>
            import('./views/plowCloudMentor/brief.vue'),
        meta: {
            title: '耕雲導師简歴'
        },

    },
    {
        path: 'newPages/newPage',
        component: ()=> import('./views/newPages/newPage.vue'),
        meta:{
            title:'新建页面'
        }
    },
    {
        path: "/more",
        component: More,
        meta: {
            title: '最新文章訊息'
        },
    },

    {
        path: "/plowCloudMentor",
        component: PlowCloudMentor,
        children: [{
            path: 'brief',
            component: () =>
                import('./views/plowCloudMentor/brief.vue'),
            meta: {
                title: '耕雲導師简歴'
            },
        },
        {
            path: 'law',
            component: () =>
                import('./views/plowCloudMentor/lawPhase.vue'),
            meta: {
                title: '耕雲導師法相'
            },
        },
        {
            path: 'taoist',
            component: () =>
                import('./views/plowCloudMentor/taoist.vue'),
            meta: {
                title: '耕雲導師墨寶'
            },
        }, {
            path: 'mourn',
            component: () =>
                import('./views/plowCloudMentor/mourningArticle.vue'),
            meta: {
                title: '耕雲導師圓寂及哀悼文'
            },
        }, {
            path: 'firstDraft',
            component: () =>
                import('./views/plowCloudMentor/firstDraft .vue'),
            meta: {
                title: '耕雲導師年譜初稿'
            },
        }, {
            path: 'anxiang',
            component: () =>
                import('./views/plowCloudMentor/anxiangC.vue'),
            meta: {
                title: '安祥禪簡介'
            },

        }, {
            path: 'menory',
            component: () =>
                import('./views/plowCloudMentor/memory.vue'),
            meta: {
                title: '永恆的懷念'
            },
        }
        ]
    },
    {
        path: "/friendWen",
        component: FriendWen,
        children: [{
            path: 'experience',
            component: () =>
                import('./views/friendWen/experience.vue'),
            meta: {
                title: '修學心得'
            },
        },
        {
            path: 'letter',
            component: () =>
                import('./views/friendWen/letter.vue'),
            meta: {
                title: '書信往來'
            },
        }

        ]
    },
    {
        path: "/greenAndDownload",
        component: GreenAndDownload,
        children: [{
            path: 'show',
            component: () =>
                import('./views/greenAndDownload/show.vue'),
            meta: {
                title: '安祥禪結緣品'
            },
        },
        {
            path: 'ideaBook',
            component: () =>
                import('./views/greenAndDownload/ideaBook.vue'),
            meta: {
                title: '安祥禪電子書'
            },
        },
        {
            path: 'downloadBox',
            component: () =>
                import('./views/greenAndDownload/downloadBox.vue'),
            meta: {
                title: '下載區'
            },
        }
        ]
    },
    {
        path: "/information",
        component: Information,
        meta: {
            title: '活動訊息'
        },
    },
    {
        path: "/magicWeapon",
        component: MagicWeapon,
        children: [{
            path: 'word',
            component: () =>
                import('./views/magicWeapon/word.vue'),
            meta: {
                title: '耕雲導師講詞'
            },
        },
        {
            path: 'puzzleBook',
            component: () =>
                import('./views/magicWeapon/puzzleBook.vue'),
            meta: {
                title: '耕雲導師解惑錄'
            },
        },
        {
            path: 'opus',
            component: () =>
                import('./views/magicWeapon/opus.vue'),
            meta: {
                title: '耕雲導師書箋及著作'
            },
        },
        {
            path: 'poetry',
            component: () =>
                import('./views/magicWeapon/poetry.vue'),
            meta: {
                title: '耕雲導師詩歌及語法'
            },
        },
        {
            path: 'videos',
            component: () =>
                import('./views/magicWeapon/videos.vue'),
            meta: {
                title: '耕雲導師說法視頻'
            },
        },
        {
            path: 'frenchp',
            component: () =>
                import('./views/magicWeapon/frenchp.vue'),
            meta: {
                title: '耕雲導師法音'
            },
        },
        {
            path: 'zenMusic',
            component: () =>
                import('./views/magicWeapon/zenMusic.vue'),
            meta: {
                title: '安祥禪曲'
            },
        },
        {
            path: 'ancient',
            component: () =>
                import('./views/magicWeapon/ancientBooks.vue'),
            meta: {
                title: '學禪典籍'
            },
        }
        ]
    },
    {
        path: "/articleDetail",
        component: ArticleDetail,
        meta: {
            title: '安祥禪詳情'
        },
    },
    {
        path: "/musicDetail",
        component: MusicDetail,
        meta: {
            title: '安祥禪曲'
        },
    },
    {
        path: "/experienceDetail",
        component: ExperienceDetail,
        meta: {
            title: '安祥禪詳情'
        },
    },

    ]
})