import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/dist/css/swiper.css'
// import './common/js/rem.js'
import VueI18n from 'vue-i18n' //多语言包

import {
	fetch
} from './api/fetch'
Vue.prototype.$fetch = fetch;
//注册前端基本链接
Vue.prototype.$BaseURL = location.protocol + '//' + location.hostname + ':' + location.port + '/#';
// Vue.prototype.$BaseURL = 'http://www.anhsiangchan.org.tw/pc_tw/#';


Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);
Vue.use(VueI18n);
Vue.use(ElementUI);

let language = 'zh-TW';

// function setLang() {
// 	let lang = localStorage.getItem('language');
// 	if (!lang) {
// 		localStorage.setItem('language', 2);
// 		lang = '2';
// 	}
// 	switch (lang) {
// 		case '1':
// 			language = 'zh-CN';
// 			break;
// 		case '2':
// 			language = 'zh-TW';
// 			break;
// 		default:
// 			language = 'en-US';
// 			break;
// 	}
// }
// setLang();
const i18n = new VueI18n({
	locale: language, // 语言标识
	//this.$i18n.locale // 通过切换locale的值来实现语言切换
	messages: {
		'zh-TW': require('./common/lang/tw'), // 繁体语言包
		'zh-CN': require('./common/lang/cn'), // 中文语言包
		'en-US': require('./common/lang/en') // 英文语言包
	}
})

// router.beforeEach((to, from, next) => {
// 	/* 路由发生变化修改页面title */
// 	if (to.meta.title) {
// 		document.title = to.meta.title
// 	}
// 	next();
// })


new Vue({
	router,
	i18n,
	render: h => h(App),
}).$mount('#app')