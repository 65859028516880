<!-- 文章详情 -->
<template>
  <div class="detail"  :class="{'showBlack':isBlack}">
    <div class="title">
      <span class="titleName">{{ content.news_title }}</span>
      <span class="author">{{ content.news_author }}</span>
    </div>
    <div v-html="content.news_content"></div>
  </div>
</template>

<script>
// import { getMourningDetail } from "../../api/apis";
import { fetch } from "../../api/fetch";

export default {
  name: "detail",
  props: {},
  data() {
    return {
      topTitle: "",
      detailType: "", //哪个页面跳来的
      content: {
        // news_title: "安詳之美",
        // news_author: "～一九八五年七月廿一日講於台北巿",
        // news_content:
        //     "　一、安祥是什麼？ 　　　（一）安祥是法的現量 　　　（二）安祥是禪的生命 　　　（三）安祥是正受的實證 　　　（四）安祥是最高的生活藝術 　　　（五）安祥是永不枯竭的幸福泉源 　　二、如何才能獲得安祥？ 　　　（一）唯求心安 　　　（二）宰制官能　懲治我欲 　　　（三）窮理澈源　識心達本 　　　（四）如法精進 　　三、安祥的美好 　　　（一）安祥能使人充滿生命的活力 　　　（二）安祥堪報不報之恩 　　　（三）安祥可以奉獻心力　享受人生 　　四、何以失去安祥？ 　　　（一）惑於感官　逐相而沉 　　　（二）突出自我我欲　背離責任義務 　　　（三）偏離正法　遠離中道 　　五、失去安祥的後果 　　　（一）生命失去源頭活水 　　　（二）百病從心生 　　六、如何保持安祥？ 　　　（一）反觀自心　正確認知 　　　（二）停止尋覓　守本真心 　　　（三）揚棄錯誤　不斷修正 　　　（四）即知即行　解行相應 　　七、結語 　　安祥之美，是在說明安祥的美好，當然也包含對安祥的讚美。 　　首先要辨明的是什麼叫做安祥？"
      },
      isBlack: false,
    };
  },
  methods: {
    initData(id) {
      let type = this.detailType,
        url = "";
      switch (type) {
        case "taoist": //耕云导师-安详禅墨宝文章详情
          url = "/news/getInkDetail";
          break;
        case "mourningArticle": //圆寂及哀悼
          url = "/news/getMourningDetail";
          this.isBlack = true;
          
          break;
        case "memory":
          url = "/news/getForeverRemembranceDetail";
          break;
        case "word":
          url = "/news/getSpeechDetail";
          break;
        case "puzzle":
          url = "/news/getDispelDoubtsDetail";
          break;
        case "opus":
          url = "/news/getBookListDetail";
          break;
        case "poetry": //安详禅法宝-耕云导师诗歌及法语
          url = "/news/getPoetryDetail";
          break;
        case "experience": //修学心得-修学心得详情
          url = "/news/StudyExperienceDetail";
          break;
        case "letter": //书信往来-书信往来详情
          url = "/news/getLetterDetail";
          break;
        case "information": //书信往来-书信往来详情
          url = "/news/getActivityDetail";
          break;
        case "ancientBooks": //书信往来-书信往来详情
          url = "/news/getAncientBooksDetail";
          break;
      }

      fetch({
        url: url,
        method: "post",
        data: {
          id: id,
          language: 2,
        },
      }).then((res) => {
        // this.content = res;
        // console.log(res);

        let content = {};
        content.news_title = res.news_title || res[0].audio_title;
        content.news_author = res.news_author;
        content.news_content = res.news_content || res[0].audio_content;
        content.news_content = content.news_content;
        content.news_content = content.news_content;
        content.news_content = content.news_content;
        content.news_content = content.news_content;
        if (this.isBlack) {
          content.news_content = content.news_content;
        }
        this.content = content;
        document.title = content.news_title;
      });
    },
  },
  created() {
    this.detailType = this.$route.query.name;
    this.topTitle = this.$route.query.title;
    let id = this.$route.query.id;
    this.initData(id);
  },
  mounted() {},
};
</script>

<style lang="stylus" scoped>
.detail {
  width: 900px;
  background-color: #fbf0e1;
  top: 0px;
  margin-left: 10px;
  color: #000;
  font-size: 16px;
  padding: 30px;
  line-height: 28px;
  text-align: left;

  .title {
    font-weight: 600;
    margin-bottom: 15px;
    text-align: center;

    .author {
      margin-left: 50px;
    }
  }
}

.showBlack {
   
  background-color:  #000;
  color: #ffffff !important;

  span {
    color: #ffffff !important;
  }
}
</style>