
    <!--  活动讯息-->
   <template>
    <div>
        <!-- 导航部分 -->
        <div class="plowCloudBrief">
            <filePath>
                <div slot="parseName">{{before_name}}</div>
                <div slot="currentName">{{current_cate}}</div>
            </filePath>
            <div>
				<keep-alive>
				    <router-view />
				</keep-alive>
            
            </div>
        </div>

        <!-- 内容部分 -->
        <div class="infoContent">
            <ul>
                <li @click="linkToDetail(item)" v-for="(item,index) in contentList" :key="index" >
                    <div class="time">{{item.data}}</div>
                    <div class="title">{{item.news_title}}</div>
                </li>
            </ul>
            <!-- 分页 -->
            <div class="pageBar">
                <div class="block">
                    <el-pagination
                        :pager-count="4"
                        @current-change="handleCurrentChange"
                        background
                        :page-size="page.per_page"
                        layout=" prev, pager, next"
                        :total="page.total"
                        :prev-text="$t('preText')"
                        :next-text="$t('nextText')"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import filePath from "../../components/filePath";
import { getTimeBeforeDay } from "../../common/js/common";
import {getCateList, getLetterList } from "../../api/apis";
export default {
    name: "infomation",
    components: {filePath},
    data() {
        return {
            cateId:'',
            before_name: "",
            current_cate: "",
            page: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1
            },
            contentList: [
                // {
                //     news_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // },
                // {
                //     news_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // },
                // {
                //     news_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // }
            ]
        };
    },
    watch: {
        $route() {
            this.before_name = this.$route.query.before_name;
            this.current_cate = this.$route.query.current_cate;
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.page.current_page = e;
            this.getLetter();
        },
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=information&id='+item.id);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: { name: "information", id: item.id}
            // }); //跳转到心得详情
        },
        //获取展示数据
        getLetter() {
            getLetterList(this.cateId, {
                limit: this.page.per_page,
                page: this.page.current_page
            }).then(res => {
                this.page.total = res.total;
                this.page.current_page = res.current_page;
                this.page.last_page = res.last_page;
                // this.contentList = getTimeBeforeDay(res.data, "data");
                this.contentList = res.data;
            });
        }
    },
    // mounted() {
    //     this.getLetter();
    // },
     created() {
        this.before_name = this.$route.query.before_name;
        this.current_cate = this.$route.query.current_cate;

        getCateList().then(res => {
            this.cateId = res[3][0].id;
            this.getLetter();
        })
    }
};
</script>

<style lang="stylus" scoped>
.plowCloudBrief {
    width: 100%;
}
.pageBar {
    margin-top: 42px;
    text-align: center;
}

.infoContent {
    width: 700px;
    font-size: 15px;
    margin: 20px;
    text-align: left;

    li {
        cursor: pointer;
        width: 100%;
        height: 32px;
        line-height: 32px;
        border-bottom: 1px dashed #D4D4D4;

        div {
            display: inline-block;
        }

        .time {
            width: 120px;
        }

        .title {
            width: 580px;
        }
    }
    li:hover{
        color: #1482d9;
    }
}
</style>