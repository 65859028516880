export const langType = {
    'title': '安祥禪',
    'tw': '繁體',
    'cn': '簡體',
    'en': 'English'
}
export const setToHome = "設為首頁"
export const joinLove = "加入最爱"
export const itemNumber = '項次'
export const ancientBookName = "典籍名称"
export const note1 = "注：帶有 * 號的，均配有　耕雲導師墨寶。"
export const downBtn = '點擊展開'
export const upBtn = '點擊收起'
export const bannerText = '安祥是摩訶般若的發露、禪的生命、法的現量， 是至真至善至美的體現';
export const navName = ['首頁', '耕雲導師', '安祥禪法寶', '禪友文訊', '結縁品及下載', '活動訊息'];

// export const detailName = ['首頁','耕雲導師','安祥禪法寶','禪友文訊','結縁品及下載','活動訊息'];
export const navLink = [{
        name: '耕耘导师',
        link: '/plowCloudMentor'
    },
    {
        name: '安详禅法宝',
        link: '/magicWeapon'
    },
    {
        name: '禅友文讯',
        link: '/friendWen'
    },
    {
        name: '结缘品及下载',
        link: '/greenAndDownload'
    },
    {
        name: '活动讯息',
        link: '/information'
    },
    {
        name: '首页 HOME',
        link: '/'
    }
];
export const zenFrech = '耕雲導師說法實況及法相'
export const startPlay = '点击播放'
export const moreMusic = '更多安祥禪曲'

export const lastNews = '最新文章訊息'
export const lastArticleNews = '最新活動訊息'
export const more = '更多'
export const footer = {
    title: '網站管理者',
    tel: '電話號碼：',
    address: '地址：',
    number: '郵遞區號：',
    email: 'E-mail：'
}

//首页更多栏目
export const lastDate = '日期';
export const articleTitle1 = '篇名';
export const articleCategory = '类别';

//第二栏目
export const articleTitle = '文章標題'
export const articleName = '作者'
export const articleYear = "年限" //耕雲導師年谱初稿
export const articleAge = "年齡"
export const articleEvent = "重要事蹟"
export const plowCloudBrief = "安祥禪簡介"
export const memonyTitle = "永恆的懷念"
export const song = "禪曲"
export const downLoad = "下载"
export const songNane = "禪曲名稱"
export const songStyle = "類型"
    //(提示信息)
export const noteInfo = {
    title1: "註：非寬頻用戶最好下載回去再聽，以免產生斷音現象。",
    info1: '安祥禪曲--歌詞下載(WORD格式)',
    info2: '安祥禪曲--mp3檔案下載',
    info3: '安祥禪曲Midi伴奏欣賞',
    info4: '安祥之歌I、 安祥之歌II、 安祥之歌III、 安祥之歌-簡譜本、 牧牛圖頌專輯 、禪宗祖師心要歌集',
    title2: '安祥禪曲歌譜集下載：'
}



//第五栏目,下载栏目
export const androidTitle = "安祥禪電子書-安卓手機版"
export const appleTitle = "安祥禪電子書-蘋果手機版"
export const downloadCate = "類別"
export const downloadConten = "內容"
export const downloadExplain = "說明"
export const downloadInclude = "包含"
export const downEdition1 = "繁體版："
export const downEdition2 = "簡體版："
export const note = "註："
export const includesBook = "安祥禪電子書所包含的主要書目如下："
export const downName = "名 稱"
export const downStyle = "格式"
export const updateTime = "更新日期"
export const fileStyle = "Word格式"
export const bookBinding = "裝 訂"
export const Images = "圖　片"
export const getWay = "安祥禪結緣品索取辦法"
    //返回首页
export const backToHome = '返回首页';
//返回
export const back = '返回';
export const firstHome = '首页';
export const contactUs = "联系我们";
export const thePath = "路径"

//分页管理
export const preText = '上一页'
export const nextText = '下一页'

// export const numList = ['(一)', '(二)', '(三)', '(四)', '(五)', '(六)', '(七)', '(八)', '(九)', '(十)', '(十一)', '(十二)', '(十三)', '(十四)', '(十五)', '(十六)']
export const numList1 = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六']
    //底部版本信息
export const bottomInfo1 = "本站歡迎無償鏈接及轉載，共濟友情。"
export const scanNum = " 瀏覽人次："
export const bottomInfo2 = "解析度1024x768或以上，瀏覽器建議使用IE10.0或FireFox2.0以上版本"