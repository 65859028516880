export const langType = {
    'title': '安祥禅',
    'tw': '繁体',
    'cn': '简体',
    'en': 'English'
}
export const setToHome = "设为首页"
export const joinLove = "加入最爱"
export const itemNumber = '项次'
export const ancientBookName = "典籍名称"
export const note1 = "注：带有 * 号的，均配有耕云导师墨宝。"
export const downBtn = '点击展开'
export const upBtn = '点击收起'
export const bannerText = '安祥是摩诃般若的发露、禅的生命、法的现量， 是至真至善至美的体现';
export const navName = ['首页', '耕云导师', '安祥禅法宝', '禅友文讯', '结縁品及下载', '活动讯息'];

// export const detailName = ['首頁','耕雲導師','安祥禪法寶','禪友文訊','結縁品及下載','活動訊息'];
export const navLink = [{
        name: '耕云导师',
        link: '/plowCloudMentor'
    },
    {
        name: '安祥禅法宝',
        link: '/magicWeapon'
    },
    {
        name: '禅友文讯',
        link: '/friendWen'
    },
    {
        name: '结縁品及下载',
        link: '/greenAndDownload'
    },
    {
        name: '活动讯息',
        link: '/information'
    },
    {
        name: '首页 HOME',
        link: '/'
    }
];
export const zenFrech = '耕云导师说法实况及法相'
export const startPlay = '点击播放'
export const moreMusic = '更多安祥禅曲'

export const lastNews = '最新文章讯息'
export const lastArticleNews = '最新活动讯息'
export const more = '更多'
export const footer = {
    title: '网站管理者',
    tel: '电话号码：',
    address: '地址：',
    number: '邮递区号：',
    email: 'E-mail：'
}

//首頁更多栏目
export const lastDate = '日期';
export const articleTitle1 = '篇名';
export const articleCategory = '类别';

//第二栏目
export const articleTitle = '文章标题'
export const articleName = '作者'
export const articleYear = "年限" //耕雲導師年谱初稿
export const articleAge = "年龄"
export const articleEvent = "重要事迹"
export const plowCloudBrief = "安祥禅简介"
export const memonyTitle = "永恒的怀念"
export const song = "禅曲"
export const downLoad = "下载"
export const songNane = "禅曲名称"
export const songStyle = "类型"
    //(提示信息)
export const noteInfo = {
    title1: "注：非宽频用户最好下载回去再听，以免产生断音现象。",
    info1: '安祥禅曲--歌词下载(WORD格式)',
    info2: '安祥禅曲--mp3档案下载',
    info3: '安祥禅曲Midi伴奏欣赏',
    info4: '安祥之歌I、 安祥之歌II、 安祥之歌III、 安祥之歌-简谱本、 牧牛图颂专辑 、禅宗祖师心要歌集',
    title2: '安祥禅曲歌谱集下载：'
}



//第五栏目,下载栏目
export const androidTitle = "安祥禅电子书-安卓手机版"
export const appleTitle = "安祥禅电子书-苹果手机版"
export const downloadCate = "类别"
export const downloadConten = "內容"
export const downloadExplain = "说明"
export const downloadInclude = "包含"
export const downEdition1 = "繁体版："
export const downEdition2 = "简体版："
export const note = "注："
export const includesBook = "安祥禅电子书所包含的主要书目如下："
export const downName = "名 称"
export const downStyle = "格式"
export const updateTime = "更新日期"
export const fileStyle = "Word格式"
export const bookBinding = "装 订"
export const downloadPage="下载页面"
export const Images = "图片"
export const getWay = "安祥禅结缘品索取办法"
    //返回首頁
export const backToHome = '返回首页';
//返回
export const back = '返回';
export const firstHome = '首页';
export const contactUs = "联系我们";
export const thePath = "路径"

//分頁管理
export const preText = '上一页'
export const nextText = '下一页'

// export const numList = ['(一)', '(二)', '(三)', '(四)', '(五)', '(六)', '(七)', '(八)', '(九)', '(十)', '(十一)', '(十二)', '(十三)', '(十四)', '(十五)', '(十六)']
export const numList1 = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六','十七','十八','十九','二十','二十一','二十二']
    //底部版本信息
export const bottomInfo1 = "本站欢迎无偿链接及转载，共济友情。"
export const scanNum = " 浏览人次："
export const bottomInfo2 = "解析度1024x768或以上，浏览器建议使用IE10.0或FireFox2.0以上版本"
