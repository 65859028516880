import axios from 'axios'
// import { Message } from 'element-ui'
// import { response } from 'express';
// import {
//     Toast
// } from 'vant';

// let BASE_URL = "http://xuntengfc.b.langqiyun.cn";
export function fetch(options) {
    return new Promise((resolve, reject) => {
        options.url = '/api' + options.url;
        //添加语言
        if (options.data) {
            options.data.language = 2; //1简体 2繁体
        } else {
            options.data = {};
            options.data.language = 2; //1简体 2繁体
        }

        // options.data.language = localStorage.getItem('language') || 1; //1简体 2繁体
        options.method = options.method || 'post'; //默认请求方式为post
        const instance = axios.create({
            // baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
            }
            // timeout: 10000
        });
        instance(options)
            .then(response => {

                if (response.status == '200') {
                    if (response.data.status == "SUCCESS") { //只过滤返回服务器端状态为200的数据

                        resolve(response.data.data);
                    } else {
                        // Message(response.data.message);
                        // Toast.fail(response.data.message);
                        reject(response.data.message);
                    }
                } else {
                    // Toast.fail(response.data.message);
                    reject(response.data.message)
                }

            })
            .catch(err => {
                // Message(err);
                reject(err);
            })
    });
}