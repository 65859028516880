<template>
  <div>
    <!-- 头部 -->
    <header class="header flex-between">
      <div class="flex-start">
        <img @click="linkToHome" src="../static/img/home/logo.png" />
        <!-- <div class="header-title">{{ $t('langStyleType.title') }}</div> -->
        <div class="langStyle">
          <ul class="flex-start">
            <li name="tw" @click="linkToHome">繁體</li>
            <li
              name="cn"
              @click="changeLang('http://chanxue.pc.langqiyun.cn/')"
            >
              简体
            </li>
            <li
              name="en"
              style="padding-right: 0rem !important; border-right: 0rem"
            >
              <a href="http://www.eg-anhsiangchan.url.tw/" target="_blank"
                >English</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="header-right">
        <ul class="flex-end">
          <li @click="linkToHome">{{ $t("firstHome") }}</li>
          <li @click="contentusemail">{{ $t("contactUs") }}</li>
        </ul>
        <p class="mt10">{{ dateInfo }}</p>
      </div>
    </header>
    <!-- 头部的导航 -->
    <nav v-show="showNav">
      <NavLink></NavLink>
    </nav>
  </div>
</template>

<script>
import NavLink from "../components/NavLink.vue";
import { nowCalendar } from "../common/js/calendar.js"; //引入日历，显示当前日期-星期-天干地支年-农历日期
import { getHome } from "../api/apis";
export default {
  name: "headNav",
  components: { NavLink },
  props: {
    showNav: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      dateInfo: "",
      email: "",
    };
  },
  methods: {
    contentusemail() {
      alert("郵箱：" + this.email);
    },
    //切换语言
    // changeWord(e) {
    //     let name = e.target.getAttribute("name");
    //     switch (name) {
    //         case "cn":
    //             this.$i18n.locale = "zh-CN";
    //             localStorage.setItem("language", 1);

    //             break;
    //         case "tw":
    //             this.$i18n.locale = "zh-TW";
    //             localStorage.setItem("language", 2);

    //             break;
    //         case "en":
    //             this.$i18n.locale = "en-US";
    //             localStorage.setItem("language", 3);
    //             break;
    //     }
    //     //切换语言后，重新请求当前页面数据(刷新浏览器,且跳转到首页)
    //     // this.getHomeInfo();
    //     if(this.$route.path=='/'){
    //         window.location.reload();
    //     }else{
    //         this.$router.push({path:'/'});
    //         window.location.reload();
    //     }

    //     // window.location.reload();
    // },
    changeLang(url) {
      //清空浏览器缓存
      localStorage.clear();
      window.open(window.location.origin + "/pc_cn/#");
    },
    linkToHome() {
      this.$router.push({
        path: "/",
      });
      document.title = "安祥禪網站";
      localStorage.setItem("nowIndex1", 5);
    },
  },
  mounted() {
    getHome().then((res) => {
      this.email = res.home.email;
    });
    this.dateInfo = nowCalendar();
  },
};
</script>

<style lang="stylus" scoped>
.header {
  font-size: 15px;
  height: 148px;
  /* line-height: 2.35rem; */
  padding-left: 13px;
  padding-right: 13px;
  position: relative;

  img {
    width: 200px;
    height: 96px;
    cursor: pointer;

    .langStyle {
      ul {
        width: 177px;
        height: 21px;
        color: #3890D7;
      }
    }
  }
}

.langStyle ul li {
  cursor: pointer;
  margin-top: 10px;
  font-size: 15px;
  padding: 0px 10px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-right: 1px solid #047bcd;
  color: #3890D7;
}

.header-right {
  margin-top: -80px;

  ul {
    cursor: pointer;

    li {
      padding: 0px 10px;
      height: 21px;
      line-height: 21px;
      text-align: center;
      border-right: 1px solid #333333;
      color: #333333;
    }

    li:last-child {
      border-right: 0px solid #cccccc;
      padding-right: 0px;
    }
  }
}

a {
  color: #3890D7;
  text-decoration: none;
}
</style>