<template>
    <!-- 路由跳转 -->
    <div class="navLink">
        <div v-for="(item,index) in $t('navLink')" :key="index">
            <div class="linkItem" @click="linkToHome(index)">{{$t('navLink')[index].name}}</div>
            <ul class="navChild">
                <li @click="linkToPage(item1,$t('navLink')[index].name,index,index1)" class="navChildItem flex-start"
                    v-for="(item1,index1) in childNav[index]" :key="index1"
                    :class="{'activeTitle':index==nowIndex1&&index1==nowIndex2}">
                    <div class="navImg flex-center">
                        <img src="../static/img/home/right3.png" alt />
                    </div>
                    <div class="title oneLine" :class="{showLetter:index===0&&index1==3, showLetter1:index===1&&(index1==2||index1==3)}">{{item1.cate_name}}</div>
                </li>
            </ul>
        </div>

        <div></div>
    </div>
</template>

<script>
    import {
        getCateList
    } from "../api/apis";
    export default {
        name: "navLink",
        watch: {
            $route() {
                this.nowIndex1 = localStorage.getItem("nowIndex1") || 5;
                this.nowIndex2 = localStorage.getItem("nowIndex2") || 0;
            }
        },
        data() {
            return {
                nowIndex1: 5,
                nowIndex2: 0,
                navLinkList: [
                    // {
                    //     id: 1,
                    //     name: "首頁",
                    //     link: "/"
                    // },
                    // {
                    //     id: 2,
                    //     name: "耕雲導師",
                    //     link: "/plowCloudMentor"
                    // },
                    // {
                    //     id: 3,
                    //     name: "安祥禪法寶",
                    //     link: "/magicWeapon"
                    // },
                    // {
                    //     id: 4,
                    //     name: "禪友文訊",
                    //     link: "/friendWen"
                    // },
                    // {
                    //     id: 5,
                    //     name: "結縁品及下載",
                    //     link: "/greenAndDownload"
                    // },
                    // {
                    //     id: 6,
                    //     name: "活動訊息",
                    //     link: "/information"
                    // }
                ],
                // 子菜单
                childNav: [],
                childLink: [
                    [
                        "/plowCloudMentor/brief",
                        "/plowCloudMentor/law",
                        "/plowCloudMentor/taoist",
                        "/plowCloudMentor/mourn",
                        "/plowCloudMentor/firstDraft",
                        "/plowCloudMentor/anxiang",
                        "/plowCloudMentor/menory"
                    ],
                    [
                        "/magicWeapon/word",
                        "/magicWeapon/puzzleBook",
                        "/magicWeapon/opus",
                        "/magicWeapon/poetry",
                        "/magicWeapon/videos",
                        "/magicWeapon/frenchp",
                        "/magicWeapon/zenMusic",
                        "/magicWeapon/ancient"
                    ],
                    ["/friendWen/experience", "/friendWen/letter"],
                    [
                        "/greenAndDownload/show",
                        "/greenAndDownload/ideaBook",
                        "/greenAndDownload/downloadBox"
                    ],
                    ["/information"]
                ]
            };
        },
        methods: {
            addLink(arr) { //除了处理跳转链接以外，还需要处理多语言（多语言的字段:cate_name:繁体，j_name：简体，e_name是英文）
                let _this = this;
                let language = 2; //1简体；2繁体3英文
                return arr.map((item, index) => {
                    return item.map((item2, index2) => {
                        if (language == 1) {
                            item2.cate_name = item2.j_name;
                        } else if (language == 2) {
                            item2.cate_name = item2.cate_name;
                        } else {
                            item2.cate_name = item2.e_name;
                        }
                        item2.link = _this.childLink[index][index2];
                        return item2;
                    });
                });
            },
            linkToHome(index) {
                if (index == 5) {
                    this.$router.push({
                        path: "/"
                    });
                    localStorage.setItem("nowIndex1", 5);
                }
            },
            linkToPage(item, parse_name, index, index1) {
                this.nowIndex1 = index;
                this.nowIndex2 = index1;
                localStorage.setItem("nowIndex1", index);
                localStorage.setItem("nowIndex2", index1);
                this.$router.push({
                    path: item.link,
                    query: {
                        before_name: parse_name,
                        current_cate: item.cate_name
                    }
                });
            }
        },
        mounted() {
            this.nowIndex1 = localStorage.getItem("nowIndex1") || 5;
            this.nowIndex2 = localStorage.getItem("nowIndex2") || 0;
            getCateList().then(res => {
                //需要交换一下 下载 和 活动讯息 的位置（也就是下标为3和4的数据位置）
                let changeItem = res[3];
                res[3] = res[4];
                res[4] = changeItem;
                this.childNav = this.addLink(res);
                // this.childNav = res;
            });
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
    .navLink {
        width: 161px;
        margin-left: 10px;
        /* font-family: 'Microsoft Yahei'; */
		/* font-family: "幼圆"; */
		font-family: "YouYuan";
		font-weight: bold;
        color: #333333;

        // position:fixed;
        .linkItem {
            text-align: left;
            padding-left: 10px;
            background-image: url('../static/img/home/btn_bg.png');
            background-size: 100%;
            width: 100;
            height: 29px;
            font-size: 15px;
            line-height: 29px;
            color: #ffffff;
            margin-top: 5px;
        }

        // .linkItem:first-child{
        // cursor pointer;
        // background-size: 120%;
        // }
        .navChild {
            border: 1px solid #E2E2E2;
            border-top: 0px solid #E2E2E2;
            border-bottom: 0px solid #E2E2E2;

            li:hover {
                color: #2684c4;
            }

            .activeTitle {
                color: #2684c4;
            }

            .showLetter {
                letter-spacing: -1px;
			}
			.showLetter1{
				letter-spacing: -1px;
			}

            .navChildItem {
                cursor: pointer;
                font-size: 15px;
                line-height: 29px;
                border-bottom: 1px solid #E2E2E2;
                position: relative;

                .navImg {
                    width: 12px;
                    margin-left: 3px;
                    height: 17px;
                }

                .title {}
            }
        }
    }
</style>