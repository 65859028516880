<template>
    <div class="flex-between">
        <!-- 最新文章讯息 ,该列表最多显示5条信息-->
        <div class="news">
            <div class="newsTitle flex-between">
                <div class="flex-start">
                    <div>{{$t('lastNews')}}</div>
                    <img src="../static/img/home/New.png" alt="news" />
                </div>
            </div>
            <div class="newsContent">
                <router-link
					:to="'/articleDetail?name=experience&id='+item.id"
					target="_blank"
                    v-for="(item,index) in studyList"
                    :key="index"
                    class="contenItem flex-between"
                    v-bind:class="{ 'contenItemDeep': index%2}"
                >
                    <p class="oneLine">{{item.news_title}}</p>
                    <!-- <time class="fz59">{{item.data}}</time> -->
                </router-link>
                <div class="moreItem">
                    <router-link to="/more" class="more">{{$t('more')}}</router-link>
                </div>
            </div>
        </div>
        <!-- 最新活动讯息 -->
        <div class="news news1">
            <div class="newsTitle flex-between">
                <div class="flex-start">
                    <div>{{$t('lastArticleNews')}}</div>
                    <img src="../static/img/home/New.png" alt="news" />
                </div>
            </div>
            <div class="newsContent">
                <router-link
					:to="'/articleDetail?name=information&id='+item.id"
					target="_blank"
                    v-for="(item,index) in activityList"
                    :key="index"
                    class="contenItem flex-between"
                    v-bind:class="{ 'contenItemDeep': index%2}"
                >
                    <p class="oneLine">{{item.news_title}}</p>
                    <!-- <time class="fz59">{{item.data}}</time> -->
                </router-link>
                <div class="moreItem">
                    <router-link :to="'/information?before_name='+before_name+'&current_cate='+current_cate" class="more">{{$t('more')}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getTimeBeforeDay } from "../common/js/common";
import {getCateList} from '../api/apis';
export default {
    name: "HelloWorld",
    props: ["study", "activity"],
    watch: {
        study(res) {
            //最多显示5条数据
            if (Array.isArray(res)) {
                if (res.length > 5) {
                    res = res.slice(0, 5);
                }
                // this.studyList = getTimeBeforeDay(res, "data");
                this.studyList = res;
            }
        },
        //最多显示5条数据
        activity(res) {
            if (Array.isArray(res)) {
                if (res.length > 5) {
                    res = res.slice(0, 5);
                }
                // this.activityList = getTimeBeforeDay(res, "data");
                this.activityList = res;
            }
        }
    },
    data() {
        return {
            studyList: [{}],
            activityList: [{}],
            before_name:'',
            current_cate:''
        };
    },
    created(){
        getCateList().then(res => {
          this.before_name = res[3][0].cate_name;
          this.current_cate = res[3][0].cate_name;  
        })
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.news {

    width: 215px;
    border: 1px solid #E2E2E2;

    /* font-size: 0.9rem; */
    .newsTitle {
        padding: 0px 10px;
        height: 28px;
        background-color: #ffffff;
        color: #3890D7;
        line-height: 28px;
        font-size: 15px;
        font-weight: 600;

        img {
            width: 30px;
            margin-left: 10px;
            margin-top: -5px;
        }
    }

    .newsContent {
        a {
            text-decoration: none;
            color: #2B80C3;
        }

        background-color: #DECDD5;
        padding-top: 6px;

        .contenItem {
            background-color: #fff;
            height: 28px;
            line-height: 28px;
            padding: 0px 10px;
            color: #333333;
            font-size: 15px;
            
        }

        .moreItem {
            text-align: right;
            line-height: 20px;
            padding-right: 10px;
            border-top: 1px solid #E2E2E2;
            font-size: 15px;
            color: #2B80C3;
            background-color: #ffffff !important;
            border-bottom: 6px solid #F4F3EE;

            a {
                text-decoration: underline;
            }
        }
    }
}

.news1 {
    width: 358px;
}

.newsTitle, .newsContent, time {
    color: #999;
}

.contenItemDeep {
    background-color: #F4F3EE !important;
}
</style>