// 页面当前路径
<template>
    <div class="pathCompontent">
        <div class="filePath flex-start">
            <img src="../static/img/file.png" alt />
            <span>{{$t('thePath')}}：</span>
            <p @click="linkToHome" class="home">{{$t('firstHome')}}</p>>
            <p>
                <slot name="parseName"></slot>
            </p>>
            <p>
                <slot name="currentName"></slot>
            </p>
        </div>
        <div class="title">
            <slot name="currentName"></slot>
        </div>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    name: "filePath",
    methods: {
        linkToHome() {
            this.$router.push({ path: "/" });
            localStorage.setItem("nowIndex1", 5);
        }
    }
};
</script>

<style lang='stylus' scoped>
.pathCompontent {
    width: 100%;
    color: #666666;
    font-size: 14px;

    .filePath {
        .home {
            cursor: pointer;
        }

        p {
            padding: 0px 5px;
        }

        // p:first-child{
        // cursor:pointer;
        // background-color:yellow;
        // }
        p:last-child {
            cursor: pointer;
        }
    }

    .title {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        position: relative;
        hegiht: 40px;
        line-height: 40px;
        text-align: left;
        margin-left: 25px;
        margin-top: 10px;
    }

    .title:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 20px;
        border-radius: 4px;
        background-color: #2684c4;
        left: -15px;
        top: 10px;
    }

    .line {
        margin-top: 10px;
        width: 100%;
        height: 1px;
        border-bottom: 2px dashed #D4D4D4;
    }
}
</style>