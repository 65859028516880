<!-- 心得详情 -->
<template>
  <div class="detail">
   
  </div>
</template>

<script>
  
  export default {
    name: "",
    components: {
      
    },
    props: {},
    data() {
      return {
        
      };
    }
  };
</script>

<style scoped>
  
</style>