<template>
  <div id="app" class="flex-column-start">
    <div class="app">
      <Header :showNav="showLeft"></Header>
      <div class="content flex-between-start">
        <div v-show="showLeft" style="width: 161px">
          <LeftNav></LeftNav>
        </div>
        <div style="width: 798px; height: auto">
          <!-- <keep-alive> -->
          <router-view></router-view>
          <!-- </keep-alive> -->
        </div>
      </div>
      <footer class="footer">
        <div class="bar_bottom mt10"></div>
        <div class="bootomInfo">
          <div class="text">{{ $t("bottomInfo1") }}</div>
          <div class="seeNum" v-if="$route.path == '/'">
            {{ $t("scanNum") }}
            <span class="mum">{{ view_num }}</span>
          </div>
        </div>
      </footer>
    </div>
    <!-- <div class="publish">{{$t('bottomInfo2')}}</div> -->
    <!-- <div class="publish">
      <a href="http://www.beian.miit.gov.cn/"> 京ICP準05043713號 </a>
    </div> -->
  </div>
</template>

<script>
import Header from "./components/header";
import LeftNav from "./components/leftNav";
import { getviewnum, addviewnum } from "./api/apis";
export default {
  name: "app",
  components: { Header, LeftNav },
  data() {
    return {
      showLeft: true,
      view_num: 0,
    };
  },
  watch: {
    $route(val) {
      let path = this.$route.path;
      if (path === "/articleDetail" || path === "/musicDetail") {
        this.showLeft = false;
      } else {
        this.showLeft = true;
      }
      console.log(val.path);
      if (val.path == "/") {
        this.getview();
        addviewnum().then((res) => {
          console.log(res);
        });
      }
    },
  },
  methods: {
    getview() {
      getviewnum().then((res) => {
        console.log(res);
        this.view_num = res.view_num2;
      });
    },
  },
  created() {
    let path = this.$route.path;
    if (path === "/articleDetail" || path === "/musicDetail") {
      this.showLeft = false;
    } else {
      this.showLeft = true;
    }
    this.getview();
  },
    mounted() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      window.location.href = "http://www.anhsiangchan.org.tw/m/#";
    }
  },
};
</script>


<style lang="stylus">
@import './common/css/index.css';

#ideaBook{
  #pc-jt{
    td{
      height: 50px !important
    }
  }
}

/* 自定义字体 */
@font-face {
  font-family: 'YouYuan';
  src: url('./static/font/youyuan.ttf');
}

.pageBar .el-pagination button, .el-pagination span {
  padding: 0px 5px;
  color: #333333;
  border-radius: 4px;
}

.pageBar .el-pagination.is-background .btn-next, .pageBar .el-pagination.is-background .btn-prev, .pageBar .el-pagination.is-background .el-pager li {
  border-radius: 4px;
  color: #333333;
  background-color: #ffffff;
}

.pageBar .el-pagination.is-background .btn-next.disabled, .pageBar .el-pagination.is-background .btn-next:disabled, .pageBar .el-pagination.is-background .btn-prev.disabled, .pageBar .el-pagination.is-background .btn-prev:disabled, .pageBar .el-pagination.is-background .el-pager li.disabled {
  border-radius: 4px;
  color: #333333;
  /* background-color:#ffffff; */
}

.pageBar .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #037acc;
  border: 1px solid #037acc;
  color: #ffffff;
}

.pageBar .el-pagination.is-background .btn-next, .pageBar .el-pagination.is-background .btn-prev, .pageBar .el-pagination.is-background .el-pager li {
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
}

#app {
  width: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
  background-color: rgb(236, 238, 230);
  font-size: 14px;
  min-width: 980px;
}

.app {
  width: 980px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 5px;
}

.content {
  width: 980px;
}

.footer {
  .bar_bottom {
    width: 960px;
    height: 4px;
    background-color: #C0C0C0;
    margin-left: 10px;
  }

  .bootomInfo {
    color: #666666;
    position: relative;
    line-height: 40px;
    text-align: center;

    .seeNum {
      width: 200px;
      position: absolute;
      right: -25px;
      top: 0px;

      .num {
        color: #00006C;
      }
    }
  }
}

.publish {
  line-height: 50px;
  color: #666666;

  a {
    text-decoration: none;
    color: #666666;
  }
}

h2, h3, h4 {
  font-size: 15px;
}
</style>
