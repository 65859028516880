 <!-- 安祥曲（音乐）详情 -->
 <template>
  <div class="detail">
    <div class="title">
      <span class="titleName">{{ content.audio_title }}</span>
      <!-- <span class="author" v-show="type!='poetry'">{{content.audio_brief}}</span> -->
    </div>
    <div class="titlePic">
      <!-- <img src="../../static/img/plowCloudMentor/music.png" alt /> -->
      <audio
        ref="audio"
        style="
          width: 500px;
          height: 30px;
          margin-top: 10px;
          margin-bottom: 10px;
        "
        controls="controls"
        loop="loop"
      >
        您的浏览器不支持 audio 标签。
      </audio>
    </div>
    <div class="audio_brief" v-if="content.audio_brief">{{ content.audio_brief }}</div>
    <div>
      <div v-html="content.audio_content"></div>
    </div>
  </div>
  <!-- <div>
        <div class="titleRange">
            <div class="title">{{content.audio_title}}</div>
            <div class="titleOther">{{content.audio_brief}}</div>
          
            <div class="titlePic">
                <img src="../../static/img/plowCloudMentor/music.png" alt />
                <audio
                    style="width:80%;height:1.5rem;margin-top:0.6rem"
                    :src="content.audio_url"
                    controls="controls"
                    loop="loop"
                    autoplay="autoplay"
                >您的浏览器不支持 audio 标签。</audio>
            </div>
        </div>
        <div class="content">{{content.audio_content}}</div>
        <div class="content" v-html="content.audio_content"></div>
    </div>-->
</template>

 <script>
import { fetch } from "../../api/fetch";

export default {
  name: "",

  props: {},
  data() {
    return {
      type: "",
      topTitle: "",
      content: {
        // id: "",
        // audio_title: "安詳之美",
        // other: "～一九八五年七月廿一日講於台北巿",
        // audio_url: "",
        // audio_content: "耕雲先生出生於一九二四年農曆五月一日天津市。乳名「大有」。幼入私塾，學名「連元」，字「仲三」，十二歲讀完《四書》及《左傳》。 一九四二年隨軍駐西康，依瑪西其安上師習學白教密法，得灌頂為阿奢黎。一九四九年隨軍到台灣，一九八三年秋退休。 先生感於國事，更名為「挽」，在台期間，深痛天涯淪落，海角飄零，乃銳志參禪，於一九七四年桃園龍潭軍中「桶底脫落」，了畢大事。一九八二年應禪學會之請求， 允為導師，為有緣「解粘去縛，抽釘拔楔」，迄二○○○年圓寂，計十八年整，其間歷次所說法要，名曰「安祥禪」 ，印有專輯，弘揚寰宇，頗獲有緣信受奉行，無異佛陀在世。 先生之禪，一掃公案、葛藤，作風平實，法力則不可思議，聆聞開示者，每於言下證入金剛心體，恢復心的原態（本來面目），信乎「以心傳心」 之不誣也。 先生少言身世，謹集所聞片段，由弟子拾綴如上。"
      },
    };
  },
  methods: {
    getMuisc() {
      let url = "",
        { name, id } = this.$route.query;
      this.type = name;
      switch (name) {
        case "frenchp":
          url = "/news/getSoundDetail";
          break;
        case "poetry":
          url = "/news/getSoundDetail";
          break;
        default:
          url = "/news/getSoundDetail";
          break;
      }
      fetch({
        url: url,
        method: "post",
        data: {
          id: id,
          language: 2,
        },
      }).then((res) => {
        let content = res;
        console.log(content.audio_brief.length,'123')
        if (content.audio_content) {
          content.audio_content = content.audio_content.replace(
            /<img/g,
            '<img style="max-width:100%"'
          );
          content.audio_content = content.audio_content.replace(
            /<table /g,
            '<table style="width:100%"'
          );
        }
        this.content = content;
        this.$refs.audio.src = content.audio_url;
        document.getElementsByTagName("title")[0].innerText =
          content.audio_title;
      });
    },
  },
  mounted() {
    this.getMuisc();
    this.topTitle = this.$route.query.title;
  },
};
</script>

 <style lang="stylus" scoped>
 .detail {
   width: 900px;
   background-color: #FBF0E1;
   top: 0px;
   margin-left: 10px;
   color: #000000;
   font-size: 15px;
   padding: 30px;
   line-height: 28px;

   .title {
     font-weight: 600;
     margin-bottom: 15px;
     text-align: center;

     .titleName {
     }

     .author {
       margin-left: 50px;
     }
   }
 }

 .audio_brief {
   white-space: pre-wrap;
 }

 .titlePic {
   margin-top: 10px;
   text-align: center;
 }

 .titlePic img {
   width: 500px;
 }
</style>