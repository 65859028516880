<!-- 首页-文章讯息 -->
<template>
    <div class="memory">
        <!-- 路径 -->
        <div class="pathCompontent">
            <div class="filePath flex-start">
                <img src="../static/img/file.png" alt />
                <span>{{$t('thePath')}}：</span>
                <p @click="linkToHome" class="home">{{$t('firstHome')}}</p>>
                <p>{{$t('lastNews')}}</p>
            </div>
         
            <div class="line"></div>
        </div>
        <!-- 内容 -->
        <table border="1" cellspacing="0">
            <tr>
                <th class="title number">{{$t('lastDate')}}</th>
                <th class="title">{{$t('articleTitle1')}}</th>
                <th class="title">{{$t('articleCategory')}}</th>
            </tr>
            <tr v-for="(item,index) in content" :key="index">
                <td class="number">{{item.data}}</td>
                <td @click="linkToDetail(item)" class="articleName">{{item.news_title}}</td>
                <td @click="toclass(item)" style="cursor:pointer;">{{item.cate_name}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import { getTimeBeforeDay } from "../common/js/common";
import { getMoreStudy } from "../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            content: []
        };
    },
    methods: {
        toclass(item){
            console.log(item)
            if(item.cate_name == '修学心得'){
                this.$router.push({
                    path:"/friendWen/experience",
                    query:{
                        // id:0,
                        current_cate:item.cate_name,
                        before_name:'禅友文讯'
                    }
                })
            }
            if(item.cate_name == '书信往来'){
                this.$router.push({
                    path:"/friendWen/letter",
                    query:{
                        // id:1,
                        current_cate:item.cate_name,
                        before_name:'禅友文讯'
                    }
                })
            }
        },
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=experience&id='+item.id+'&title='+this.$t("lastNews"));
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: {
            //         name: "experience",
            //         id: item.id,
            //         title: this.$t("lastNews")
            //     }
            // }); //跳转到心得详情
        },
        linkToHome() {
            this.$router.push({ path: "/" });
            localStorage.setItem("nowIndex1", 5);
        }
    },
    created() {
        getMoreStudy().then(res => {
            // this.content = getTimeBeforeDay(res, "data");
            //改为繁体
            res.map((item)=>{
                if(item.cate_name == '修学心得'){
                    item.cate_name = '修學心得'
                }
                if(item.cate_name == '书信往来'){
                    item.cate_name = '書信往來'
                }
            })
            // console.log(res)
            this.content = res;
        });
        //重新设置表格颜色
    }
};
</script>

<style lang="stylus" scoped>
.ta-l {
    text-align: left;
}

.pl5 {
    padding-left: 5px;
}


.memory {
    color: #333333;
    width: 778px;
    /* height: 726px; */
    margin: 0px 5px;

    .title {
        letter-spacing: 5px;
    }

    .number {
        width: 120px;
        text-align: center;
    }

    .articleName {
        width: 424px;
    }

    .articleName:hover {
        color: #1482d9;
        cursor: pointer;
    }
}

table, td, tr, th {
    border: 1px solid #ACACAC;
}

table {
    width: 100%;
    font-size: 15px;

    td, th {
        line-height: 30px;
        text-align: left;
        padding-left: 5px;
    }
}

.pathCompontent {
    width: 100%;
    color: #666666;
    font-size: 14px;

    .filePath {
        .home {
            cursor: pointer;
        }

        p {
            padding: 0px 5px;
        }
    }

    .line {
        margin-top: 10px;
        width: 100%;
        height: 1px;
        border-bottom: 2px dashed #D4D4D4;
        margin-bottom: 15px;
    }
}
</style>