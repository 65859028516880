import {
    fetch
} from './fetch'
//首页
export function getHome() {
    return fetch({
        url: '/home/getHome',
        method: 'post'

    })
}
export function getMoreStudy() {
    return fetch({
        url: '/home/getMoreStudy',
        method: 'post'

    })
}

//获取浏览量
export function getviewnum(){
    return fetch({
        url:'/home/getViewNum',
        method:'post'
    })
}

//增加浏览量
export function addviewnum() {
    return fetch ({
        url:'/home/addViewNum',
        method:'post',
    })
}


//首页获取禅曲
export function getAudio() {
    return fetch({
        url: '/home/getAudio',
        method: 'post'

    })
}
// ---------耕耘导师
//获取菜单列表
export function getCateList() {
    return fetch({
        url: '/news_cate/getCateList',
        method: 'post'

    })
}
// 耕耘导师-安详禅简历
export function getResume(id) {
    return fetch({
        url: '/news/getResume',
        method: 'post',
        data: {
            id: id
        }
    })
}
// 耕耘导师-法相
export function getPicture(id, page) {
    return fetch({
        url: '/news/getPicture',
        method: 'post',
        data: {
            id: id,
            page: page.page,
            limit: page.limit
        }
    })
}

// 耕耘导师-安详禅墨宝文章详情
export function getInkDetail(id) {
    return fetch({
        url: '/news/getInkDetail',
        method: 'post',
        data: {
            id: id
        }
    })
}
//耕云导师-安详禅墨宝文章列表
export function getInkList(id) {
    return fetch({
        url: '/news/getInkList',
        method: 'post',
        data: {
            id: id
        }
    })
}
// 耕耘导师-年谱初稿
export function getChronicle(id) {
    return fetch({
        url: '/news/getChronicle',
        method: 'post',
        data: {
            id: id
        }
    })
}



// 耕耘导师-耕云导师圆寂及哀悼文列表
export function getMourningList(id) {
    return fetch({
        url: '/news/getMourningList',
        method: 'post',
        data: {
            id: id
        }
    })
}

// 耕耘导师-安祥禅简介
export function getIntroduction(id) {
    return fetch({
        url: '/news/getIntroduction',
        method: 'post',
        data: {
            id: id
        }
    })
}

// 耕耘导师-永恒的怀念列表
export function getForeverRemembranceList(id) {
    return fetch({
        url: '/news/getForeverRemembranceList',
        method: 'post',
        data: {
            id: id
        }
    })
}


// 安详禅法宝-耕耘导师讲词菜单列表
export function getSpeechList(id) {
    return fetch({
        url: '/news/getSpeechList',
        method: 'post',
        data: {
            id: id
        }
    })
}

// 安详禅法宝-耕云导师解惑录列表
export function getDispelDoubtsList(id) {
    return fetch({
        url: '/news/getDispelDoubtsList',
        method: 'post',
        data: {
            id: id
        }
    })
}

//安详禅法宝-耕云导师书笺及著作列表
export function getBookList(id) {
    return fetch({
        url: '/news/getBookList',
        method: 'post',
        data: {
            id: id
        }
    })
}
//安详禅法宝-耕云导师诗歌及法语
export function getPoetry(id) {
    return fetch({
        url: '/news/getPoetry',
        method: 'post',
        data: {
            id: id
        }
    })
}
//安详禅法宝-禅曲
export function getAudioList(id) {
    return fetch({
        url: '/news/getAudioList',
        method: 'post',
        data: {
            id: id
        }
    })
}



//安详禅法宝-耕耘导师说法视频列表
export function getVideoList(id) {
    return fetch({
        url: '/news/getVideoList',
        method: 'post',
        data: {
            id: id
        }
    })
}

//安详禅法宝-耕耘导师说法视频下载
// export function videosDownload(data) {
//     return fetch({
//         url: '/index/videosDownload',
//         method: 'post',
//         data: {
//             file_title: data.file_title,
//             file_url: data.file_url
//         }
//     })
// }
export function videosDownload(id) {
    return fetch({
        url: '/index/videosDownload',
        method: 'post',
        data: {
            id: id
        }
    })
}



//安详禅法宝-耕云导师法音列表
export function getSoundCate(id) {
    return fetch({
        url: '/news/getSoundCate',
        method: 'post',
        data: {
            id: id
        }
    })
}
//安祥禅法宝-学禅典籍列表
export function getAncientBooksList(id) {
    return fetch({
        url: '/news/getAncientBooksList',
        method: 'post',
        data: {
            id: id
        }
    })
}
//下载
//文件下载接口
export function downloadFile(url) { //url是拼接了域名的
    return fetch({
        url: '/index/downloadFile',
        method: 'post',
        data: {
            file_url: url
        }
    })
}
//安祥禅结缘品
export function getGoodsList(id) {
    return fetch({
        url: '/goods/getGoodsList',
        method: 'post',
        data: {
            id: id
        }
    })
}
//安祥禅电子书
export function getEbookList() {
    return fetch({
        url: '/goods/getEbookList',
        method: 'post',
    })
}
//下载区
export function getDownLoadList(id) {
    return fetch({
        url: '/goods/getDownLoadList',
        method: 'post',
        data: {
            id: id
        }
    })
}

//安祥禅结缘品索取办法
export function getAcquisitionList() {
    return fetch({
        url: '/goods/getAcquisitionList',
        method: 'post'

    })
}


//禅友文讯

//修学心得-修学心得菜单
export function StudyExperienceCate(id) {
    return fetch({
        url: '/news/StudyExperienceCate',
        method: 'post',
        data: {
            id: id
        }
    })
}
//修学心得-修学心得列表
export function StudyExperienceList(id, page) {
    return fetch({
        url: '/news/StudyExperienceList',
        method: 'post',
        data: {
            id: id,
            page: page.page,
            limit: page.limit

        }
    })
}

//书信往来-书信来往列表
export function getLetterList(id, page) {
    return fetch({
        url: '/news/getLetterList',
        method: 'post',
        data: {
            id: id,
            page: page.page,
            limit: page.limit

        }
    })
}

//活动讯息-活动讯息列表
export function getActivityList(id, page) {
    return fetch({
        url: '/news/getActivityList',
        method: 'post',
        data: {
            id: id,
            page: page.page,
            limit: page.limit

        }
    })
}

//链接接口
export function herfList() {
    return fetch({
        url: '/news/getAudioLink',
        method: 'post',
        data: {}
    })
}